<template>
  <div>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-container>
          <v-icon>mdi-face-woman</v-icon>
          <br>
          Schön, dass du alle Tests gemacht hast :)
          Vielleicht kann dir Elea noch anders weiterhelfen?
          Oder mache nochmal einen Test :)
        </v-container>
      </v-col>
    </v-row>
    <br>
    <v-row
      style="background:#F8F4EE"
    >
      <v-col>
        <v-container
          v-for="(test, index) in all" :key="index"
        >
          <TestSelection
            :id="test"
            :key="`testSelection${index}`"
          />
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'All',
  components: {
    TestSelection: lazyLoad('components/Cards/TestSelection'),
  },
  created() {
    this.reset();
  },
  computed: {
    ...mapGetters('testMacro', [
      'all',
    ]),
  },
  methods: {
    ...mapActions('testMacro', [
      'reset',
    ]),
  },
};
</script>
